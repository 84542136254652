export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.stg.camisa7.botafogo.com.br/',
  //apiURL: 'http://localhost:3333/',
  weURL: 'https://admin.stg.camisa7.botafogo.com.br/',
  recaptcha: {
    key: '6LdTaCgbAAAAADr7Vd5QEi43lAy2XnDDjfD28nkB'
  },
  auth: {
    new: false,
    social: [
      { name: 'google', provider: '854729594288-ago2rg8f9q7a7fqsolg7ej76tivtqptv.apps.googleusercontent.com' },
      { name: 'facebook', provider: '687338948735678' }
    ],
    cognito: {
      enable: true,
      enableSSO: true,
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_MRasMJe6N',
      APP_CLIENT_ID: '1ot8uc5vvkq26k65rnu0sal2ao',
      FRONT_URL: 'https://stg.camisa7.botafogo.com.br',
      SSO_ENDPOINT: 'https://camisa7-auth.auth.us-east-1.amazoncognito.com',
      FBANK_CLIENT_ID: '2kohcbpm96l25dk6fhtvk7fqg3',
      FBANK_AUTH_TOKEN: 'MmtvaGNicG05NmwyNWRrNmZodHZrN2ZxZzM6NDUzZXZqbGhhbHRxamdpb3BtamtlbDZyZGl0czZzaTNkN2xvb24wNGlma3Y4MWpxaGhq'
    }
  },
};