export const fengTheme = {
    name: 'feng',
    properties: {

        '--background': '#fff',
        '--on-background': '#000',
        '--primary': '#414141',
        '--on-primary': '#fff',
        '--primary-RGB': '65, 65, 65',
        '--primary-light': '#A7A8A9',
        '--primary-dark': '#1B1B1B',
        '--secondary': '#BC8422',
        '--on-secondary': '#000',
        '--secondary-RGB': '191,146,64',
        '--surface': '#fff',
        '--on-surface': '#000',
        '--surface-RGB': '0, 0, 0',
        '--error': '#F25455',
        '--on-error': '#fff',
        '--error-RGB': '255, 255, 255',
        '--warning': '#EFC845',
        '--on-warning': '#fff',
        '--warning-RGB': '255, 255, 255',
        '--success': '#68DB6C',
        '--success-RGB': '104,219,108',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--on-info': '#fff',
        '--info-RGB': '255, 255, 255',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'BFRSans',
        '--font-title': 'BFRSans-Bold',
        '--font-button': 'BFRSans',

         //TEMPORARY FONTS
         '--unlogged-font-primary': 'Barlow-Regular',
         '--unlogged-font-title': 'TuskerGrotesk',
         '--unlogged-font-button': 'Barlow-SemiBold',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'white',
        '--menu-active-background': '#BC8422',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'linear-gradient(270deg, #1B1B1B 17.92%, #323232 110.16%)',
        '--header-mobile-logo-width': '100px',
        '--header-desktop-logo-width': '160px',
        '--header-mobile-height': '130px',
        '--header-desktop-height': '120px',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '235px',

        // Home Public
        // Call to Action
        '--home-call-to-action-background': 'linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%)',
        '--home-call-to-action-background-img': 'url(https://botafogo.s3.amazonaws.com/prod/images/scroll1-bg.png)',
        '--home-call-to-action-detail-color': '#fff',
        '--home-call-to-action-title-font-size': '24.7822px',
        '--home-call-to-action-title-margin': '60px 0 5px 0',
        '--home-call-to-action-content-padding': '130px 0 120px',
        '--home-call-to-action-button-margin': '30px 0 0',
        '--home-call-to-action-subtitle-width' : '537px',
        '--home-call-to-action-subtitle-line-height': '21px',
        '--home-call-to-action-button-background': 'var(--secondary)',
        '--home-call-to-action-button-padding': '8px 30px',
        '--home-call-to-action-button-font-size': '20px',
        '--home-call-to-action-button-style': 'normal',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '44px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',
        '--home-call-to-action-height': '700px',

        // Tabs 
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',

        // Plans
        '--plans-background': 'url(https://botafogo.s3.amazonaws.com/prod/images/Home-2024/bg-planos.png)',
        '--plans-background-outside':'url(https://botafogo.s3.amazonaws.com/prod/images/Home-2024/bg-planos.png)',
        

        // Contact
        '--contact-background': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-contact.png) no-repeat center center / cover',
        '--panel-header-title-color': 'var(--primary)',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        //Landing
        '--landing-page-title-register-color':'#000',
        '--header-bottom-landing': '#BC8422',
        '--landing-page-button-register-color': '#BC8422',
        '--header-bg-landing': '#000',

        // X-Ray
        '--x-ray-call-to-action-background': '#000',
        '--x-ray-call-to-action-background-img': 'url(https://botafogo.s3.amazonaws.com/prod/images/xray-header-bg.jpg)',
        '--x-ray-action-active-background-color': '#000',
        '--x-ray-action-not-active-background-color': '#414141',
        '--x-ray-tab-header-top-position': '-30px',
        '--x-ray-tab-header-ink-bar-height': '0',
        '--x-ray-chart-colors': [
            'rgba(0, 0, 0, 1.0)',
            'rgba(0, 0, 0, 0.75)',
            'rgba(48, 48, 48, 1.0)',
            'rgba(48, 48, 48, 0.75)',
            'rgba(49, 49, 49, 1.0)',
            'rgba(49, 49, 49, 0.75)',
            'rgba(149, 149, 149, 1.0)',
            'rgba(0, 0, 0, 1.0)',
            'rgba(0, 0, 0, 0.75)',
            'rgba(48, 48, 48, 1.0)',
            'rgba(48, 48, 48, 0.75)',
            'rgba(49, 49, 49, 1.0)',
            'rgba(49, 49, 49, 0.75)',
            'rgba(149, 149, 149, 1.0)',
            'rgba(0, 0, 0, 1.0)',
            'rgba(0, 0, 0, 0.75)',
            'rgba(48, 48, 48, 1.0)',
            'rgba(48, 48, 48, 0.75)',
            'rgba(49, 49, 49, 1.0)',
            'rgba(49, 49, 49, 0.75)',
            'rgba(149, 149, 149, 1.0)',
        ],

        // Events
        '--event-background-select': 'rgba(255, 255, 255, 0.5)',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#bc8422',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': '#bc8422',
        '--event-color-text-dark': '#313131',
        '--event-color-text-primary': '#7C7C7C',
        '--event-color-text-secondary': '#bc8422',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': 'white',
        '--footer-background': ' #000',
        '--footer-logo-width': '140px',

        // Auth Modal
        '--auth-modal-stlogo-width': '140px',
        '--auth-modal-stlogo-bg': 'black',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',

        // Auth Modal
        '--auth-cognito-logo-bg': 'linear-gradient(270deg, #1B1B1B 17.92%, #323232 110.16%)',
        '--retrieve-cognito-logo-bg': 'linear-gradient(270deg, #1B1B1B 17.92%, #323232 110.16%)',
    },
    images: {
        'stLogo': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23.svg',
        'stLogoAuth': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23.svg',
        'stLogoMobile': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23.svg',
        'stLogoFooter': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23.svg',
        'loadingLogo': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23.svg',
        'stShield': 'https://botafogo.s3.amazonaws.com/prod/images/logo.png',
        '--homePlansBG': 'url(https://botafogo.s3.amazonaws.com/prod/images/bota-bg3.jpg)',
        '--homeTicketsBG': 'url(https://flamengo.s3-sa-east-1.amazonaws.com/prod/assets/images/st-tickets-stadium-with-bg.jpg)',
        '--homeExclusiveContentBG': 'url(https://flamengo.s3-sa-east-1.amazonaws.com/prod/assets/images/grounge_stripe_bg_black.png)',
        'mailPendingLogo': 'https://botafogo.s3.amazonaws.com/prod/images/2023/camisa7logo-23-preto.svg',
    },
    videos: {
        'bannerVideo': 'https://botafogo.s3.amazonaws.com/prod/images/Home-2024/video-hero-banner.mp4'
    },
    texts: {
        // Home
        // Call to Action
        'callToActionTitle': 'somos a',
        'callToActionSubTitle': '<strong> estrela mais <BR> tradicional </strong>',
        'callToActionFeaturedImg': '',
        'callToActionDetail': '#Desde1894',
        'callToActionButton': 'JUNTE-SE A NÓS',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso sistema. Recomendamos, para a sua segurança, que altere a senha gerada que foi enviada para o seu e-mail.',
        'firstAccessTextChange': 'Gostaríamos de informar que nossos Termos de Uso e Política de Privacidade foram atualizados. Clique abaixo para ler ou em OK para continuar a navegação pelo portal do sócio.'
    }
}
